<template>
    <div>
        <div class="ls-center hide-on-tablet hide-on-desktop">
            <p><i>Select category to view listing</i></p>
        </div>
        <div class="row mobile-categories hide-on-tablet hide-on-desktop">
            <div class="col-12">
                <ul id="listing_cats" class="nav listings-categoriesj">
                    <li v-bind:class="category.id === currentCategory
                        ? 'nav-item mobile-listing-cats active'
                        : 'nav-item mobile-listing-cats'
                        " v-for="category in listingCategories" :key="category.id">
                        <button class="btn btn-lg" @click="listingCategory(category.id)">{{ category.name }} <b>{{
                            category.listings_count
                        }}</b></button>
                    </li>
                </ul>
            </div>
            <div class="col-2" style="display: none">
                <i id="toggle_no_wrap" class="icon-chevron-down-circle" style="font-size: 30px"></i>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                <!-- The Listings for the Selected Category -->

                <div class="ls-center">
                    <h2 v-if="!currentCategoryName">Pahukama BnB</h2>
                    <h2 v-else>{{ currentCategoryName }} Category</h2>
                </div>

                <div class="row" v-if="meta && meta.total != 0 && !isLoading">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-12" v-for="listing in listings" :key="listing.id">
                        <router-link :to="{ name: 'listing', params: { id: listing.id } }">
                            <div class="row ls-listing-card">
                                <!--Image -->
                                <div v-if="listing.images.length > 0" class="col-12"
                                    style="padding-left: initial; padding-right: initial">
                                    <img :src="listing.images[0]?.url" alt="Shop" />
                                </div>
                                <!--Listing details -->
                                <div class="col-12 listing_item_info">
                                    <h4>
                                        {{ listing.title }}
                                    </h4>
                                    <div>
                                        {{ listing.user?.name }}
                                    </div>
                                </div>
                                <div class="col-12 listing_item_info">
                                    <h4>${{ listing.price }}</h4>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table ps-table ps-table--vendor">
                        <tfoot>
                            <tr v-if="(!meta || meta.total === 0) && !isLoading">
                                <td colspan="7" class="text-center m-4 text-danger p-4">
                                    No Listings available
                                </td>
                            </tr>
                            <tr v-else-if="isLoading">
                                <td colspan="7" class="text-center m-4 text-danger">
                                    Loading listings...
                                </td>
                            </tr>
                            <tr v-else>
                                <td class="text-right" colspan="7">
                                    <ul class="pagination text-right">
                                        <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                                            <a href="#">Prev Page<i class="icon-chevron-left"></i></a>
                                        </li>
                                        <li class="active">
                                            <a href="#">Page {{ pageNumber }}</a>
                                        </li>
                                        <!--                      <li><a href="#">3</a></li>-->
                                        <li v-on:click="goToNextPage()" v-if="hasNextPage">
                                            <a href="#">Next Page<i class="icon-chevron-right"></i></a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
    name: "Listings",
    data() {
        return {
            listings: [],
            listingCategories: [],
            currentCategory: "",
            currentCategoryName: "",
            isLoading: false,
            meta: null,
            isLoading: false,
            isDeleting: false,
            links: {},
            url: null,
            hasNextPage: false,
            nextPage: null,
            hasPrevPage: false,
            previousPage: null,
            pageNumber: 1,
        };
    },
    computed: {
        ...mapState({
            auth: (state) => state.auth,
            cart: (state) => state.cart,
        }),
    },

    mounted() {
        // console.log("User Data : " + JSON.stringify(auth.user));
        this.getListings();

        $("#toggle_no_wrap").on("click", function () {
            $("#listing_cats").toggleClass("listings-categories");
            $(this).toggleClass("icon-chevron-down-circle");
            $(this).toggleClass("icon-chevron-up-circle");
        });
    },
    methods: {

        getListings() {
            this.isLoading = true;
            axios.get("listings?category_id=" + 10).then((response) => {
                // console.log(response.data.data);
                this.listings = response.data.data;
                this.meta = response.data.meta;
                this.links = response.data.meta.links;
                this.count = response.data.meta.total;
                this.pageNumber = response.data.meta.current_page;
                this.handleNextPage(response.data.meta.links);
                this.handlePrevPage(response.data.meta.links);

                this.listings.length = response.data.meta.total;
                this.isLoading = false;
            });
        },

        scrollTop() {
            let element = document.getElementsByClassName("table-responsive");
            if (element.length > 0) {
                const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
                window.scroll({
                    top: y,
                    behavior: "smooth",
                });
            }
        },

        goToNextPage: function () {
            this.url = this.nextPage;
            if (this.url) {
                this.getProducts();
            }
            this.scrollTop();
        },

        goToPrevPage: function () {
            this.url = this.prevPage;
            if (this.url) {
                this.getProducts();
            }
            this.scrollTop();
        },

        handleNextPage(links) {
            if (links.next) {
                this.hasNextPage = true;
                this.nextPage = links.next;
            } else {
                this.hasNextPage = false;
            }
        },

        handlePrevPage(links) {
            if (links.prev) {
                this.hasPrevPage = true;
                this.prevPage = links.prev;
            } else {
                this.hasPrevPage = false;
            }
        },
    },
};
</script>
<style scoped>
.listing-image {
    max-width: 70px;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1;
}
</style>
  